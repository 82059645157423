@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body, #root {
  font-family: Roboto;
  background: black;
  height: 100%;
}

a {
  color: #84A98C;
}

.reveal .controls {
  color: #84A98C;
}

.reveal .progress {
  color: #354F52;
}
